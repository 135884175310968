import * as React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import CardProduct from "../components/productPage/cardProduct"
import Layout from "../components/layout"

import dataLayerHandler from "../components/yandexMetrica/dataLayerHandler"
import Landing from "../components/productPage/landing"
import Gallery from "../components/productPage/gallery"
import DeliveryCards from "../components/productPage/delivery"
import TabPanel from "../components/productPage/tabPanel"
import CharacteristicsBlock from "../components/productPage/characteristics"
import SimilarProducts from "../components/productPage/similarProducts"

import { GlobalDispatchContext } from "../context/GlobalContextProvider"
import { Helmet } from "react-helmet"

const Product = ({
  pageContext,
  data: { prismicProduct, allPrismicProduct, variants, prismicVariantPay },
}) => {
  const dispatch = React.useContext(GlobalDispatchContext)

  React.useEffect(() => {
    return function () {
      const last_products =
        JSON.parse(localStorage.getItem("last_products")) ?? []

      const index = last_products.findIndex(
        product => prismicProduct.id === product?.id
      )

      if (last_products.length > 9) {
        index === -1
          ? last_products.splice(-1, 1)
          : last_products.splice(index, 1)
      } else if (index !== -1) {
        last_products.splice(index, 1)
      }
      last_products.unshift(prismicProduct)
      if (prismicProduct.data.images[0].image.localFile !== null) {
        localStorage.setItem("last_products", JSON.stringify(last_products))
        dispatch({ type: "UPD_LAST_PRODUCTS", payload: last_products })
      }
    }
  })

  const img = prismicProduct?.data?.photos.map(
    photo =>
      photo?.image?.localFile?.childImageSharp?.gatsbyImageData?.images.fallback
        .src
  )

  React.useEffect(() => {
    dataLayerHandler("view", prismicProduct)
  }, [])
  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            url: `https://krypton.ru/catalog/${
              prismicProduct?.data?.category.uid
            }/${prismicProduct?.data?.brand.uid}/${
              prismicProduct?.data?.model_document?.document?.uid ||
              prismicProduct?.data?.code_model
                .toLowerCase()
                .replaceAll(" ", "-")
            }/${prismicProduct?.uid}/`,
            "@type": "Product",
            name: prismicProduct?.data?.name,
            description: prismicProduct?.data?.description,

            image:
              prismicProduct?.data?.images[0]?.image.localFile?.childImageSharp
                ?.gatsbyImageData?.images.fallback.src || "",
            offers: {
              "@type": "Offer",
              priceCurrency: "RUB",
              price: prismicProduct?.data.price,
            },
          })}
        </script>
      </Helmet>
      <Seo
        title={prismicProduct.data.seo_title ?? prismicProduct.data.name}
        description={prismicProduct.data.seo_description}
      />
      <TabPanel
        links={[
          {
            title: "О товаре",
            href: "#about_product",
          },
          {
            title: "Описание",
            href: "#description",
            visible: prismicProduct.data.body2.length !== 0,
          },
          {
            title: "Фото",
            href: "#photo",
            visible: prismicProduct?.data?.photos[0]?.image.localFile !== null,
          },
          {
            title: "Характеристики",
            href: "#characteristics",
          },
          {
            title: "Доставка",
            href: "#delivery",
          },
        ]}
      />
      <div id="about_product" />
      <CardProduct
        pageContext={pageContext}
        prismicProduct={prismicProduct}
        allVariant={variants.edges}
        prismicVariantPay={prismicVariantPay.data.pay}
      />
      <SimilarProducts
        products={allPrismicProduct.edges.map(edge => edge.node)}
      />
      <div id="description" />
      <Landing slices={prismicProduct.data.body2} />
      <div id="photo" />
      <Gallery
        imagesArr={prismicProduct.data.photos.map(photo => photo.image)}
      />
      <div id="characteristics" />
      <CharacteristicsBlock product={prismicProduct} />
      <div id="delivery" />
      <DeliveryCards prismicProduct={prismicProduct} />
    </Layout>
  )
}

/**
 * Шаблон страницы продукта
 * @module src/templates/product
 * @param {Object} props - объект свойств компонента React
 * @param {Object} props.data - объект данных полученый из prismic
 * @param {Object} props.pageContext - объект контекста, передаваемый при формировании страницы
 */
export default Product

export const pageQuery = graphql`
  query ProductBySlug(
    $uid: String!
    $subcategory: String!
    $modelTitle: String!
  ) {
    allPrismicProduct(
      filter: { data: { category: { uid: { eq: $subcategory } } } }
      limit: 7
    ) {
      edges {
        node {
          id
          uid
          data {
            model_document {
              document {
                ... on PrismicProductModel {
                  uid
                  data {
                    model_text {
                      html
                    }
                    name {
                      text
                    }
                  }
                }
              }
            }
            code_model
            brand {
              uid
              document {
                ... on PrismicBrand {
                  data {
                    name
                  }
                }
              }
            }
            category {
              uid
              document {
                ... on PrismicSubcategory {
                  data {
                    name
                  }
                }
              }
            }
            body {
              ... on PrismicProductBodySeller {
                id
                primary {
                  match_city_price
                  city
                  seller {
                    document {
                      ... on PrismicSeller {
                        id
                        data {
                          inn
                          is_legal_entity
                          ogrn
                          shop_name
                          avatar {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                slice_type
              }
            }
            all_product_accessories {
              product_accessories {
                document {
                  ... on PrismicProduct {
                    id
                    uid
                    data {
                      code_model
                      brand {
                        uid
                        document {
                          ... on PrismicBrand {
                            data {
                              name
                            }
                          }
                        }
                      }
                      category {
                        uid
                        document {
                          ... on PrismicSubcategory {
                            data {
                              name
                            }
                          }
                        }
                      }
                      name
                      price
                      old_price
                      images {
                        image {
                          localFile {
                            childImageSharp {
                              gatsbyImageData
                            }
                          }
                          alt
                        }
                      }
                      delivery {
                        document {
                          ... on PrismicDelivery {
                            data {
                              body {
                                ... on PrismicDeliveryBodyDeliveryToCities {
                                  id
                                  items {
                                    city_name
                                    cost
                                    delivery_description
                                    timing
                                  }
                                }
                              }
                              variants {
                                description
                                name
                              }
                            }
                          }
                        }
                      }
                      credit {
                        document {
                          ... on PrismicCredit {
                            data {
                              months_1
                              months_2
                              percent
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            code_model
            name
            price
            old_price
            color_group
            sale_product
            images {
              image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(height: 200)
                  }
                }
              }
            }
            delivery {
              document {
                ... on PrismicDelivery {
                  data {
                    body {
                      ... on PrismicDeliveryBodyDeliveryToCities {
                        id
                        items {
                          city_name
                          cost
                          delivery_description
                          timing
                        }
                      }
                    }
                    variants {
                      description
                      name
                    }
                  }
                }
              }
            }
            credit {
              document {
                ... on PrismicCredit {
                  data {
                    months_1
                    months_2
                    percent
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicProduct(uid: { eq: $uid }) {
      id
      uid
      data {
        code_model
        category {
          uid
          document {
            ... on PrismicSubcategory {
              id
              data {
                name
              }
            }
          }
        }
        brand {
          document {
            ... on PrismicBrand {
              id
              data {
                name
                body {
                  ... on PrismicBrandBodyLogo {
                    id
                    slice_type
                    primary {
                      image {
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                            fluid(maxHeight: 35) {
                              aspectRatio
                              src
                              srcSet
                              srcSetWebp
                            }
                          }
                        }
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
        }
        name
        memory
        property
        color_group
        certificate
        old_price
        price
        images {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
                fluid(
                  maxWidth: 750
                  srcSetBreakpoints: [
                    750
                    700
                    650
                    600
                    550
                    500
                    450
                    400
                    350
                    300
                    250
                    200
                    150
                    100
                    50
                  ]
                  fit: CONTAIN
                ) {
                  srcSetWebp
                  srcSet
                  src
                  aspectRatio
                }
              }
            }
          }
        }
        photos {
          image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 400
                  width: 1224
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                  outputPixelDensities: [0.2, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
                  sizes: "(min-width: 1280px) 1224px, 95.62vw"
                )
              }
            }
          }
        }
        delivery {
          document {
            ... on PrismicDelivery {
              data {
                body {
                  ... on PrismicDeliveryBodyDeliveryToCities {
                    id
                    items {
                      city_name
                      cost
                      delivery_description
                      timing
                    }
                  }
                }
                variants {
                  description
                  name
                }
              }
            }
          }
        }
        credit {
          document {
            ... on PrismicCredit {
              data {
                months_1
                months_2
                percent
              }
            }
          }
        }
        seo_title
        seo_description
        body {
          ... on PrismicProductBodySeller {
            id
            primary {
              match_city_price
              city
              seller {
                document {
                  ... on PrismicSeller {
                    id
                    data {
                      inn
                      is_legal_entity
                      ogrn
                      shop_name
                      avatar {
                        url
                      }
                    }
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicProductBodyStickers {
            slice_type
            items {
              sticker {
                document {
                  ... on PrismicSticker {
                    id
                    data {
                      image {
                        alt
                        localFile {
                          publicURL
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProductBodyFeatures {
            slice_type
            items {
              feature
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(height: 30)
                  }
                }
                alt
              }
            }
          }
        }
        body1 {
          ... on PrismicProductBody1Characteristics {
            items {
              value
              characteristic {
                document {
                  ... on PrismicCharacteristic {
                    id
                    data {
                      name
                      variant
                    }
                  }
                }
              }
            }
            primary {
              title
            }
          }
        }
        description
        documents {
          doc_title {
            text
          }
          file {
            url
          }
          doc_description {
            text
          }
        }
        body2 {
          ... on PrismicProductBody2OnlyText {
            slice_type
            primary {
              accent_text
              bold_text
              normal_text
              small_text
              order
            }
          }
          ... on PrismicProductBody2OneImage {
            slice_type
            primary {
              order
              image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      height: 400
                      width: 1224
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                      outputPixelDensities: [
                        0.25
                        0.5
                        0.75
                        1
                        1.25
                        1.5
                        1.75
                        2
                      ]
                      sizes: "(min-width: 1280px) 1224px, 95.62vw"
                    )
                  }
                }
              }
            }
          }
          ... on PrismicProductBody2TwoImages {
            slice_type
            primary {
              order
              big_text_1
              big_text_2
              color_text_1
              color_text_2
              normal_text_1
              normal_text_2
              priority_1
              priority_2
              vertical_align_1
              vertical_align_2
              image_1 {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      height: 400
                      width: 598
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                      outputPixelDensities: [
                        0.35
                        0.5
                        0.75
                        1
                        1.25
                        1.5
                        1.75
                        2
                      ]
                      sizes: "(min-width: 1280px) 598px, (max-width: 767px) 86.47vw, (max-width: 1025px) 44.96vw, 50vw"
                    )
                  }
                }
              }
              image_2 {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      height: 400
                      width: 598
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                      outputPixelDensities: [
                        0.35
                        0.5
                        0.75
                        1
                        1.25
                        1.5
                        1.75
                        2
                      ]
                      sizes: "(min-width: 1280px) 598px, (max-width: 767px) 86.47vw, (max-width: 1025px) 44.96vw, 46.71vw"
                    )
                  }
                }
              }
            }
          }
          ... on PrismicProductBody2ImageAndText {
            slice_type
            primary {
              accent_text
              bold_text
              normal_text
              order
              position_text
              small_text
              image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      height: 400
                      width: 700
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                      outputPixelDensities: [
                        0.35
                        0.5
                        0.75
                        1
                        1.25
                        1.5
                        1.75
                        2
                      ]
                      sizes: "(min-width: 1280px) 700px, (max-width: 767px) 86.47vw, (max-width: 1025px) 44.96vw, 54.68vw"
                    )
                  }
                }
              }
            }
          }
        }
        all_product_accessories {
          product_accessories {
            document {
              ... on PrismicProduct {
                id
                uid
                data {
                  code_model
                  brand {
                    uid
                    document {
                      ... on PrismicBrand {
                        data {
                          name
                        }
                      }
                    }
                  }
                  category {
                    uid
                    document {
                      ... on PrismicSubcategory {
                        data {
                          name
                        }
                      }
                    }
                  }
                  name
                  price
                  old_price
                  images {
                    image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                      alt
                    }
                  }
                  delivery {
                    document {
                      ... on PrismicDelivery {
                        data {
                          body {
                            ... on PrismicDeliveryBodyDeliveryToCities {
                              id
                              items {
                                city_name
                                cost
                                delivery_description
                                timing
                              }
                            }
                          }
                          variants {
                            description
                            name
                          }
                        }
                      }
                    }
                  }
                  credit {
                    document {
                      ... on PrismicCredit {
                        data {
                          months_1
                          months_2
                          percent
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    variants: allPrismicProduct(
      filter: { data: { code_model: { eq: $modelTitle } } }
    ) {
      edges {
        node {
          uid
          data {
            code_model
            brand {
              uid
              document {
                ... on PrismicBrand {
                  data {
                    name
                  }
                }
              }
            }
            category {
              uid
              document {
                ... on PrismicSubcategory {
                  data {
                    name
                  }
                }
              }
            }
            property
            memory
            color_group
            certificate
            body1 {
              ... on PrismicProductBody1Characteristics {
                id
                slice_type
                items {
                  characteristic {
                    document {
                      ... on PrismicCharacteristic {
                        id
                        data {
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
            body {
              ... on PrismicProductBodySeller {
                id
                primary {
                  match_city_price
                  city
                  seller {
                    document {
                      ... on PrismicSeller {
                        id
                        data {
                          inn
                          is_legal_entity
                          ogrn
                          shop_name
                          avatar {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                slice_type
              }
            }
          }
        }
      }
    }
    prismicVariantPay {
      data {
        pay {
          pay_name {
            text
          }
          visibility
        }
      }
    }
  }
`
